@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Eagle+Lake&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tilt+Neon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Tilt+Neon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Capriola&display=swap');
@import url('https://db.onlinewebfonts.com/c/961a181da27e7cbc072ec2fb5bbfe2a9?family=Circular+Std');

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/fullcalendar/5.10.0/main.min.css');

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}


.rti--container {
    /* --rti-bg: "#fff",
    --rti-border: "#ccc",
    --rti-main: "#3182ce",
    --rti-radius: "0.375rem",
    --rti-s: "0.5rem",
    --rti-tag: "#edf2f7",
    --rti-tag-remove: "#e53e3e", */
    border: none!important;
    /* margin: 1px; */
    padding: 5px 0px!important;
    width: max-content;
  }

.rti--tag {
    height: 32px !important;
    padding: 4px 10px;
    background: #FFF7E8 !important;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(28, 28, 28, 0.80);
}

.rti--tag span{
    width: max-content;
}

.rti--container:focus-within {
    border-color: none;
    box-shadow: none;
}   

.rc-tooltip-placement-top{
    padding: 5px 0 5px 0;
}

.rc-tooltip-placement-topRight{
    padding: 0 5px 0 9px
}

.reactour__helper{
    max-width: 600px!important;
}

.reactour__helper span{
    background: #ff711e;
}

.reactour__dot--is-active{
    background: #ff711e!important;
    color: #ff711e!important;
}
/* .rti--tag span {
    padding: 0 0 0 0.2rem !important;
}

.rti--container * {
    box-sizing: border-box !important;
    transition: all .2s ease !important;
    gap: 5px 0px !important;
}


.rti--container:focus-within {
    border: none !important;
    box-shadow: none !important;
} */
/* 
.rti--input {
    outline: none !important;
    border: none !important;
    width: 100% !important;
    height: 100% !important;
    font-size: 14px !important;
} */

/* .rti--input::placeholder {
    color: #919191 !important;
}

.rti--tag button {
    color: #919191 !important;
    font-size: 12px !important;
    padding: 0px 4px;
    margin-left: 4px;
}

.rti--tag button:hover { */
    /* color: white !important; */
    /* background: #D4D4D4 !important;
}

.rti-remove-input {
    display: none !important;
}

.highlight-bold {
    font-weight: 600;
} */


/* Date Filter */
/* .react-datepicker {
    border: none !important;
}

.react-datepicker-popper {
    z-index: 8; */
    /* position: unset!important;
    transform: none!important; */
/* } */
/* 
.react-datepicker__triangle {
    display: none !important;
}

.react-datepicker__header {
    border-bottom: none !important;
    background-color: none !important;
    background: none !important;
}

.react-datepicker__day {
    font-size: 12px;
}

.react-datepicker__day--in-range {
    background: #E8F5FF !important;
    color: #2E2E2E !important;
}

.react-datepicker__day--selected {
    background: #006ECC !important;
    border-radius: 15px !important;
    color: #FFFFFF !important;
}

.react-datepicker__day--outside-month {
    color: #919191 !important;
    font-weight: 300 !important;
} */

/* .react-datepicker__navigation { */
    /* display: none!important; */
/* } */

/* .react-datepicker__current-month { */
    /* display: none!important; */
/* } */

/* .react-datepicker__day-name {
    font-weight: 300;
} */

/* .rc-tooltip-inner { */
    /* width: 300px; */
/* } */

/* .fc-toolbar-chunk h2 {
    font-size: 16px !important;
    color: #555;
    font-weight: 500;
} */

/* url analysis map charts */
/* g text, g g rect{
    display: none;
} */


/* LP event calander */
.selected-date{
    background: #ffcc00 !important;
    color: black !important;
}

.fc-daygrid-day, .fc-daygrid-day-frame{
    cursor: pointer !important;
}